import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { IconContext } from "react-icons";
import { IoLogIn } from "react-icons/io5";
import { RiLoginCircleFill } from "react-icons/ri";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    height: 64,
    [theme.breakpoints.up("md")]: {
      height: 80,
      paddingTop: ".6rem",
    },
  },
  drawerMenuButton: {
    marginLeft: theme.spacing(0.5),
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  list: {
    width: 250,
  },
  listItemAvatar: {
    minWidth: 40,
  },
  rounded: {
    width: 30,
    height: 30,
    backgroundColor: "rgba(250, 202, 43, 0.15)",
  },
  drawerNavItem: {
    paddingTop: ".7rem",
    paddingLeft: ".5rem",
    paddingRigh: ".5rem",
    display: "inline-block",
    width: "100%",
    textDecoration: "none",
    color: "rgba(0, 0, 0, .9)",
    fontSize: "1rem",
  },
  active: {
    backgroundColor: "rgba(250, 202, 43, 0.15)",
    borderRadius: "6px",
    color: "#f4675b !important",
    fontWeight: "bold",
    alignItems: "center",
    "&:hover": {
      color: "#f4675b",
    },
  },
  navHeader: {
    paddingTop: ".3125rem",
    paddingBottom: ".3125rem",
    marginLeft: "1rem",
    fontSize: "1.25rem",
    textDecoration: "none",
    whiteSpace: "nowrap",
    color: "rgba(0, 0, 0, .9)",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  navItem: {
    paddingRight: ".7rem",
    paddingLeft: ".7rem",
    display: "block",
    textDecoration: "none",
    color: "rgba(0, 0, 0, .55)",
    fontSize: "1.2rem",
  },
}));

const Home = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const classes = useStyles();

  return (
    <nav className={classes.grow}>
      <div className="container-xl p-0">
        <AppBar
          classes={{ root: classes.appBar }}
          position="fixed"
          color="default"
        >
          <Toolbar>
            <>
              <IconButton
                edge="start"
                className={classes.drawerMenuButton}
                color="inherit"
                aria-label="open menu drawer"
                onClick={() => setShowDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
              >
                <div className={classes.list} role="presentation">
                  <List>
                    <IconContext.Provider
                      value={{
                        color: "#f4675b",
                        padding: "1.2em",
                      }}
                    >
                      <ListItem button>
                        <ListItemText primary="Kicstoms Sellers' Lounge" />
                      </ListItem>
                      <ListItem button>
                        <ListItemAvatar
                          classes={{ root: classes.listItemAvatar }}
                        >
                          <Avatar variant="rounded" className={classes.rounded}>
                            <IoLogIn />
                          </Avatar>
                        </ListItemAvatar>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? `${classes.drawerNavItem} ${classes.active}`
                              : `${classes.drawerNavItem}`
                          }
                          to="/signin"
                        >
                          Signin
                        </NavLink>
                      </ListItem>
                      <ListItem button>
                        <ListItemAvatar
                          classes={{ root: classes.listItemAvatar }}
                        >
                          <Avatar variant="rounded" className={classes.rounded}>
                            <RiLoginCircleFill />
                          </Avatar>
                        </ListItemAvatar>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? `${classes.drawerNavItem} ${classes.active}`
                              : `${classes.drawerNavItem}`
                          }
                          to="/signup"
                        >
                          Signup
                        </NavLink>
                      </ListItem>
                    </IconContext.Provider>
                  </List>
                </div>
              </Drawer>
              <NavLink to="/" className={classes.navHeader}>
                Kicstoms Seller's Lounge
              </NavLink>
              <div className={classes.grow}></div>
              <div className={classes.sectionDesktop}>
                <NavLink
                  to="/signin"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItem} ${classes.active}`
                      : `${classes.navItem}`
                  }
                >
                  Signin
                </NavLink>
                <NavLink
                  to="/signup"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItem} ${classes.active}`
                      : `${classes.navItem}`
                  }
                >
                  SignUp
                </NavLink>
              </div>
            </>
          </Toolbar>
        </AppBar>
      </div>
    </nav>
  );
};

export default Home;
