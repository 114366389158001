import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const enquiriesAdapter = createEntityAdapter();

const initialState = enquiriesAdapter.getInitialState();

export const fetchEnquiries = createAsyncThunk(
  "/enquiries/fetchEnquiries",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-enquiries/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const enquiriesSlice = createSlice({
  name: "enquiries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEnquiries.fulfilled, enquiriesAdapter.addMany);
  },
});

export const {
  selectAll: selectAllStoreEnquiries,
  selectById: selectStoreEnquiryById,
  selectIds: selectStoreEnquiriesIds,
  selectTotal: selectTotalStoreEnquiries,
} = enquiriesAdapter.getSelectors((state) => state.enquiries);

export default enquiriesSlice.reducer;
