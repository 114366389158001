import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const storeRequestsForReturnAdapter = createEntityAdapter();

const initialState = storeRequestsForReturnAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchStoreRequestsForReturn = createAsyncThunk(
  "storeRequestsForReturn/fetchStoreRequestsForReturn",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-requests-for-return`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const storeRequestsForReturnSlice = createSlice({
  name: "storeRequestsForReturn",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreRequestsForReturn.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStoreRequestsForReturn.fulfilled, (state, action) => {
        state.status = "succeeded";
        storeRequestsForReturnAdapter.addMany(state, action.payload);
      })
      .addCase(fetchStoreRequestsForReturn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllStoreRequestsForReturn,
  selectById: selectStoreRequestForReturnById,
  selectIds: selectStoreRequestsForReturnsIds,
} = storeRequestsForReturnAdapter.getSelectors(
  (state) => state.storeRequestsForReturn
);

export default storeRequestsForReturnSlice.reducer;
