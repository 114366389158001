import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const repliesAdapter = createEntityAdapter();

const initialState = repliesAdapter.getInitialState();

// async thunk to fetch replies to reviews
export const fetchReviewsReplies = createAsyncThunk(
  "/sneakerReviewsReplies/fetchReviewsReplies",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/sneaker-reviews-replies`
    );
    return response.data;
  }
);

const sneakerReviewsRepliesSlice = createSlice({
  name: "sneakerReviewsReplies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReviewsReplies.fulfilled, repliesAdapter.addMany);
  },
});

export default sneakerReviewsRepliesSlice.reducer;
