import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const enquiriesRepliesAdapter = createEntityAdapter();

const initialState = enquiriesRepliesAdapter.getInitialState();

// async thunk to fetch enquiry replies
export const fetchEnquiriesReplies = createAsyncThunk(
  "/enquiriesReplies/fetchEnquiriesReplies",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-enquiries-replies/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const enquiriesRepliesSlice = createSlice({
  name: "enquiriesReplies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchEnquiriesReplies.fulfilled,
      enquiriesRepliesAdapter.addMany
    );
  },
});

export default enquiriesRepliesSlice.reducer;
