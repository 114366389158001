import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const initialState = {
  status: "idle",
  revenue: null,
  error: null,
};

export const fetchRevenue = createAsyncThunk(
  "/revenue/fetchRevenue",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-revenue/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const revenueSlice = createSlice({
  name: "revenue",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevenue.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchRevenue.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.revenue = action.payload;
      })
      .addCase(fetchRevenue.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectRevenue = createSelector(
  (state) => state.revenue,
  (revenue) => revenue.revenue
);

export default revenueSlice.reducer;
