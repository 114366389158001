import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const businessAdapter = createEntityAdapter();

const initialState = businessAdapter.getInitialState();

export const fetchBusiness = createAsyncThunk(
  "/business/fetchBusiness",
  async () => {
    const response = client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-business/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBusiness.fulfilled, businessAdapter.addOne);
  },
});

export default businessSlice.reducer;
