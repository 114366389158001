import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const storeStockProductsAdapter = createEntityAdapter();

const initialState = storeStockProductsAdapter.getInitialState({
  status: "idle",
  singleProductStatus: "idle",
  error: null,
});

export const fetchStoreStockProducts = createAsyncThunk(
  "storeStockProducts/fetchStoreStockProducts",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/stock/products/vendor/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

export const fetchStoreStockProduct = createAsyncThunk(
  "storeStockProducts/fetchStockProduct",
  async (productId) => {
    const response = await client.get(
      `http://localhost:8000/stock/products/vendor/${productId}/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

export const updateStockProduct = createAsyncThunk(
  "storeStockProducts/updatedStockProduct",
  async ({
    addedUnits,
    stockedSneakers,
    leadTime,
    stockNewSelectedSizes,
    productId,
  }) => {
    const body = {
      total_units: addedUnits,
      stocked_sneakers: stockedSneakers,
      lead_time: leadTime,
      new_sizes: stockNewSelectedSizes,
    };
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/stock/products/vendor/${productId}/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const storeStockProductsSlice = createSlice({
  name: "storeStockProducts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreStockProducts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStoreStockProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        storeStockProductsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchStoreStockProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchStoreStockProduct.pending, (state, action) => {
        state.singleProductStatus = "loading";
      })
      .addCase(fetchStoreStockProduct.fulfilled, (state, action) => {
        state.singleProductStatus = "succeeded";
        storeStockProductsAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchStoreStockProduct, (state, action) => {
        state.singleProductStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(updateStockProduct.pending, (state, action) => {
        state.singleProductStatus = "updating";
      })
      .addCase(updateStockProduct.fulfilled, (state, action) => {
        state.singleProductStatus = "succeeded";
        storeStockProductsAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateStockProduct.rejected, (state, action) => {
        state.singleProductStatus = "failed update";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllStoreStockProducts,
  selectById: selectStoreStockProductById,
  selectIds: selectStoreStockProductsIds,
} = storeStockProductsAdapter.getSelectors((state) => state.storeStockProducts);

export default storeStockProductsSlice.reducer;
