import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const customersAdapter = createEntityAdapter();

const initialState = customersAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchCustomers = createAsyncThunk(
  "/customers/fetchCustomers",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/customers/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.status = "succeeded";
        customersAdapter.addMany(state, action.payload);
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllCustomers,
  selectById: selectCustomerById,
  selectIds: selectCustomerIds,
} = customersAdapter.getSelectors((state) => state.customers);

// selector to select customer that placed an order
export const selectStoreOrderCustomer = createSelector(
  [selectAllCustomers, (state, customerId) => customerId],
  (customers, customerId) =>
    customers.find((customer) => customer.customer.id === customerId)
);

export const selectStoreRequestForReturnCustomer = createSelector(
  [selectAllCustomers, (state, customerId) => customerId],
  (customers, customerId) =>
    customers.find((customer) => customer.customer.id === customerId)
);

export default customersSlice.reducer;
