import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const unitsSoldAdapter = createEntityAdapter();

const initialState = unitsSoldAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchUnitsSold = createAsyncThunk(
  "unitsSold/fetchUnitsSold",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/units-sold/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const unitsSoldSlice = createSlice({
  name: "unitsSold",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnitsSold.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUnitsSold.fulfilled, (state, action) => {
        state.status = "succeeded";
        unitsSoldAdapter.addMany(state, action.payload);
      })
      .addCase(fetchUnitsSold.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllUnitsSold,
  selectById: selectUnitsSoldById,
  selectIds: selectUnitsSoldIds,
} = unitsSoldAdapter.getSelectors((state) => state.unitsSold);

export default unitsSoldSlice.reducer;
