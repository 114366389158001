import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import storeReducer from "../features/store/storeSlice";
import revenueReducer from "../features/revenue/revenueSlice";
import revenueTransactionsReducer from "../features/revenue/revenueTransactionsSlice";
import reviewsReducer from "../features/communications/reviews/reviewsSlice";
import enquiriesReducer from "../features/communications/enquiries/enquiriesSlice";
import enquiriesRepliesReducer from "../features/communications/enquiries/enquiriesRepliesSlice";
import storeOrdersReducer from "../features/orders/storeOrdersSlice";
import sneakerReviewsRepliesReducer from "../features/communications/reviews/reviesRepliesSlice";
import customersReducer from "../features/customers/customersSlice";
import storeStockProductsReducer from "../features/stock/stockProductsSlice";
import storesneakersReducer from "../features/sneakers/storeSneakersSlice";
import sneakerSizesReducer from "../features/sneaker-sizes/sneakerSizesSlice";
import storeRequestsForReturnReducer from "../features/returns/storeRequestsForReturnSlice";
import storeReturnItemsReduser from "../features/returns/storeReturnItemsSlice";
import businessReducer from "../features/settings/business/businessSlice";
import businessOwnerReducer from "../features/settings/business/businessOwnerSlice";
import businessAddressReducer from "../features/settings/business/businessAddressSlice";
import unitsSoldReducer from "../features/sales/unitsSoldSlice";
import usersReducer from "../features/accounts/usersSlice";
import promotionsReducer from "../features/promotions/promotionsSlice";
import userReducer from "../features/user/userSlice";

// create a root reducer
const rootReducer = combineReducers({
  business: businessReducer,
  businessAddress: businessAddressReducer,
  businessOwner: businessOwnerReducer,
  customers: customersReducer,
  enquiries: enquiriesReducer,
  enquiriesReplies: enquiriesRepliesReducer,
  promotions: promotionsReducer,
  revenue: revenueReducer,
  revenueTransactions: revenueTransactionsReducer,
  reviews: reviewsReducer,
  sneakerReviewsReplies: sneakerReviewsRepliesReducer,
  sneakerSizes: sneakerSizesReducer,
  store: storeReducer,
  storeOrders: storeOrdersReducer,
  storeRequestsForReturn: storeRequestsForReturnReducer,
  storeReturnItems: storeReturnItemsReduser,
  storeSneakers: storesneakersReducer,
  storeStockProducts: storeStockProductsReducer,
  unitsSold: unitsSoldReducer,
  user: userReducer,
  users: usersReducer,
});

// redux persist config
const persistConfig = {
  key: "store",
  storage,
  whitelist: ["user"],
};

// create a persistedReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// create a store with the persistedReducer, ignoring some actions
const store = configureStore({
  reducer: persistedReducer,
  // exclude all the actions exported by redux-persist
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// persistor
export let persistor = persistStore(store);

export default store;
