import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const storeSneakersAdapter = createEntityAdapter();

const initialState = storeSneakersAdapter.getInitialState({
  status: "idle",
  createStatus: "idle",
  error: null,
});

export const fetchStoreSneakers = createAsyncThunk(
  "storeSneakers/fetchStoreSneakers",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/seller-sneakers/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

export const createSneaker = createAsyncThunk(
  "storeSneakers/createSneaker",
  async (sneakerData) => {
    const response = await fetch(
      "http://localhost:8000/seller-sneakers/create/",
      {
        method: "POST",
        body: sneakerData,
        credentials: "include",
      }
    );

    if (response.ok) {
      const sneaker = await response.json();
      return sneaker;
    }
  }
);

const storeSneakersSlice = createSlice({
  name: "storeSneakers",
  initialState,
  reducers: {
    sneakerLeadImageUpdated(state, action) {
      const { sneakerId, leadImage } = action.payload;
      const existingSneaker = state.entities[sneakerId];
      if (existingSneaker) {
        existingSneaker.lead_image = leadImage;
      }
    },
    sneakerLeadImageOtherUpdated(state, action) {
      const { sneakerId, leadImageOther } = action.payload;
      const existingSneaker = state.entities[sneakerId];
      if (existingSneaker) {
        existingSneaker.lead_image = leadImageOther;
      }
    },
    sneakerAngleviewBothUpdated(state, action) {
      const { sneakerId, angleViewBoth } = action.payload;
      const existingSneaker = state.entities[sneakerId];
      if (existingSneaker) {
        existingSneaker.angle_view_both = angleViewBoth;
      }
    },
    sneakerBothHeelsUpdated(state, action) {
      const { sneakerId, bothHeels } = action.payload;
      const existingSneaker = state.entities[sneakerId];
      if (existingSneaker) {
        existingSneaker.both_heels = bothHeels;
      }
    },
    sneakerInsolesUpdated(state, action) {
      const { sneakerId, insoles } = action.payload;
      const existingSneaker = state.entities[sneakerId];
      if (existingSneaker) {
        existingSneaker.insoles_both = insoles;
      }
    },
    sneakerOutsoleUpdated(state, action) {
      const { sneakerId, outSole } = action.payload;
      const existingSneaker = state.entities[sneakerId];
      if (existingSneaker) {
        existingSneaker.outsole = outSole;
      }
    },
    sneakerToeboxUpdated(state, action) {
      const { sneakerId, toebox } = action.payload;
      const existingSneaker = state.entities[sneakerId];
      if (existingSneaker) {
        existingSneaker.toebox = toebox;
      }
    },
    sneakerHeelMidsoleUpdate(state, action) {
      const { sneakerId, heelMidsole } = action.payload;
      const existingSneaker = state.entities[sneakerId];
      if (existingSneaker) {
        existingSneaker.heel_midsole = heelMidsole;
      }
    },
    sneakerUpdated(state, action) {
      storeSneakersAdapter.upsertOne(state, action.payload);
    },
    addedPromotionToSneaker(state, action) {
      storeSneakersAdapter.upsertOne(state, action.payload);
    },
    removedPromotionFromSneaker(state, action) {
      storeSneakersAdapter.upsertOne(state, action.payload);
    },
    deletedSneaker(state, action) {
      storeSneakersAdapter.removeOne(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreSneakers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStoreSneakers.fulfilled, (state, action) => {
        state.status = "succeeded";
        storeSneakersAdapter.addMany(state, action.payload);
      })
      .addCase(fetchStoreSneakers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createSneaker.pending, (state, action) => {
        state.createStatus = "creating";
      })
      .addCase(createSneaker.fulfilled, (state, action) => {
        state.createStatus = "idle";
        storeSneakersAdapter.addOne(state, action.payload);
      })
      .addCase(createSneaker.rejected, (state, action) => {
        state.createStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllStoreSneakers,
  selectById: selectStoreSneakerById,
  selectIds: selectStoreSneakersIds,
} = storeSneakersAdapter.getSelectors((state) => state.storeSneakers);

export const {
  sneakerLeadImageUpdated,
  sneakerLeadImageOtherUpdated,
  sneakerAngleviewBothUpdated,
  sneakerBothHeelsUpdated,
  sneakerInsolesUpdated,
  sneakerOutsoleUpdated,
  sneakerToeboxUpdated,
  sneakerHeelMidsoleUpdate,
  sneakerUpdated,
  addedPromotionToSneaker,
  removedPromotionFromSneaker,
  deletedSneaker,
} = storeSneakersSlice.actions;

export const selectStockProductSneaker = createSelector(
  [selectAllStoreSneakers, (state, stockProductId) => stockProductId],
  (storeSneakers, stockProductId) =>
    storeSneakers.find((sneaker) => sneaker.stock_product === stockProductId)
);

export default storeSneakersSlice.reducer;
