import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const reviewsAdapter = createEntityAdapter();

const initialState = reviewsAdapter.getInitialState();

// async thunk to fetch reviews
export const fetchReviews = createAsyncThunk(
  "/reviews/fetchReviews",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-reviews/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReviews.fulfilled, reviewsAdapter.addMany);
  },
});

// export the selectors
export const {
  selectAll: selectAllStoreReviews,
  selectById: selectStoreReviewById,
  selectIds: selectStoreReviewsIds,
  selectTotal: selectTotalStoreReviews,
} = reviewsAdapter.getSelectors((state) => state.reviews);

export default reviewsSlice.reducer;
