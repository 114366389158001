import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const businessOwnerAdapter = createEntityAdapter();

const initialState = businessOwnerAdapter.getInitialState();

export const fetchBusinessOwner = createAsyncThunk(
  "/businessOwner/fetchBusinessOwner",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-business/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const businessOwnerSlice = createSlice({
  name: "businessOwner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessOwner.fulfilled, businessOwnerAdapter.addOne);
  },
});

export default businessOwnerSlice.reducer;
