import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const storeReturnItemsAdapter = createEntityAdapter();
const initialState = storeReturnItemsAdapter.getInitialState({
  status: "idle",
  singleStatus: "idle",
  error: null,
  singleError: null,
});

export const fetchStoreReturnItems = createAsyncThunk(
  "storeReturnItems/fetchStoreReturnItems",
  async (requestId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store/store-return-items/${requestId}/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

export const updateReturnItemStatus = createAsyncThunk(
  "storeReturnItems/updateReturnItemStatus",
  async ({
    return_status,
    number_returned,
    return_reason,
    return_description,
    return_comment,
    returnItemId,
  }) => {
    const body = {
      return_status,
      number_returned,
      return_reason,
      return_description,
      return_comment,
    };
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/store-return-item-update/${returnItemId}/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const storeReturnItemsSlice = createSlice({
  name: "storeReturnItems",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreReturnItems.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStoreReturnItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        storeReturnItemsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchStoreReturnItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateReturnItemStatus.pending, (state, action) => {
        state.singleStatus = "updating";
      })
      .addCase(updateReturnItemStatus.fulfilled, (state, action) => {
        state.singleStatus = "succeeded";
        storeReturnItemsAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateReturnItemStatus.rejected, (state, action) => {
        state.singleStatus = "failed update";
        state.singleError = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllStoreReturnItems,
  selectById: selectStoreReturnItemById,
  selectIds: selectStoreReturnItemsIds,
} = storeReturnItemsAdapter.getSelectors((state) => state.storeReturnItems);

export default storeReturnItemsSlice.reducer;
