import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const businessAddressAdapter = createEntityAdapter();

const initialState = businessAddressAdapter.getInitialState();

export const fetchBusinessAddress = createAsyncThunk(
  "/businessAddress/fetchBusinessAddress",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/business-address/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const businessAddressSlice = createSlice({
  name: "businessAddress",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchBusinessAddress.fulfilled,
      businessAddressAdapter.addOne
    );
  },
});

export default businessAddressSlice.reducer;
