import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const initialState = {
  loginStatus: "not logged in",
  signupStatus: "not signed up",
  emailConfirmationStatus: "not confirmed",
  user: null,
  loginError: null,
  signupError: null,
};

export const signUp = createAsyncThunk("user/signUp", async (data) => {
  const response = await client.post(
    `${process.env.REACT_APP_API_ENDPOINT}/signup`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});

export const signIn = createAsyncThunk(
  "user/signIn",
  async ({ email, password }) => {
    const body = { email, password };
    const response = await client.post(
      `${process.env.REACT_APP_API_ENDPOINT}/login/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const signOut = createAsyncThunk("user/signOut", async () => {
  const response = await client.get(
    `${process.env.REACT_APP_API_ENDPOINT}/logout/`,
    { credentials: "include" }
  );

  return response.data;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    sellerRegistered(state, action) {
      state.user.seller = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state, action) => {
        state.signupStatus = "signing up";
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.signupStatus = "signed up";
      })
      .addCase(signUp.rejected, (state, action) => {
        state.signupStatus = "failed signing up";
        state.signupError = action.error.message;
      })
      .addCase(signIn.pending, (state, action) => {
        state.loginStatus = "logging in";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loginStatus = "logged in";
        state.signupStatus = "signed up";
        state.user = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loginStatus = "failed logging in";
        state.loginError = action.error.message;
      })
      .addCase(signOut.pending, (state, action) => {
        state.loginStatus = "signing out";
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.loginStatus = "signed out";
        state.user = null;
      })
      .addCase(signOut, (state, action) => {
        state.loginStatus = "failed signing out";
        state.loginError = action.error.message;
      });
  },
});

export const { sellerRegistered } = userSlice.actions;

export const selectUser = createSelector(
  (state) => state.user,
  (user) => user.user
);

export default userSlice.reducer;
