import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const sneakerSizesAdapter = createEntityAdapter();

const initialState = sneakerSizesAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchSneakerSizes = createAsyncThunk(
  "sneakerSizes/fetchSneakerSizes",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/sneakers/sizes`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  }
);

const sneakerSizesSlice = createSlice({
  name: "sneakerSizes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSneakerSizes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSneakerSizes.fulfilled, (state, action) => {
        state.status = "succeeded";
        sneakerSizesAdapter.addMany(state, action.payload);
      })
      .addCase(fetchSneakerSizes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllSneakerSizes,
  selectById: selectSneakerSizeById,
  selectIds: selectSneakerSizesIds,
} = sneakerSizesAdapter.getSelectors((state) => state.sneakerSizes);

// selector to get the sneaker sizes associated with a sneaker's stockedSneakers
export const selectStockedSneakersSizes = createSelector(
  [selectAllSneakerSizes, (state, stockedSneakers) => stockedSneakers],
  (sneakerSizes, stockedSneakers) =>
    sneakerSizes.filter((sneaker) =>
      stockedSneakers.some((s) => s.size === sneaker.id)
    )
);

export default sneakerSizesSlice.reducer;
