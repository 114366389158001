import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const revenueTransactionsAdapter = createEntityAdapter();

const initialState = revenueTransactionsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchRevenueTransactions = createAsyncThunk(
  "revenueTransactions/fetchRevenueTransactions",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/revenue-transactions`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const revenueTransactionsSlice = createSlice({
  name: "revenueTransactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevenueTransactions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchRevenueTransactions.fulfilled, (state, action) => {
        state.status = "succeeded";
        revenueTransactionsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchRevenueTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllRevenueTransactions,
  selectById: selectRevenueTransactionById,
  selectIds: selectRevenueTransactionsIds,
} = revenueTransactionsAdapter.getSelectors(
  (state) => state.revenueTransactions
);

export default revenueTransactionsSlice.reducer;
