import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const promotionsAdapter = createEntityAdapter();

const initialState = promotionsAdapter.getInitialState();

export const fetchPromotions = createAsyncThunk(
  "/promotions/fetchPromotions",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/promotions/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPromotions.fulfilled, promotionsAdapter.addMany);
  },
});

export const {
  selectAll: selectAllPromotions,
  selectById: selectPromotionById,
  selectIds: selectPromotionsIds,
} = promotionsAdapter.getSelectors((state) => state.promotions);

export default promotionsSlice.reducer;
