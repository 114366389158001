import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const storeOrdersAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.date_ordered.localeCompare(a.date_ordered),
});

const initialState = storeOrdersAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchStoreOrders = createAsyncThunk(
  "/storeOrders/fetchStoreOrders",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-orders/online/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

export const fetchStoreOrder = createAsyncThunk(
  "storeOrders/fetchStoreOrder",
  async (orderId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-orders/online/${orderId}`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const storeOrdersSlice = createSlice({
  name: "storeOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreOrders.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStoreOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        storeOrdersAdapter.addMany(state, action.payload);
      })
      .addCase(fetchStoreOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchStoreOrder.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStoreOrder.fulfilled, (state, action) => {
        state.status = "succeeded";
        storeOrdersAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchStoreOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllStoreOrders,
  selectById: selectStoreOrderById,
  selectIds: selectStoreOrdersIds,
  selectTotal: selectTotalOrders,
} = storeOrdersAdapter.getSelectors((state) => state.storeOrders);

export const selectCustomerOrders = createSelector(
  [selectAllStoreOrders, (state, customerId) => customerId],
  (storeOrders, customerId) =>
    storeOrders.filter((order) => order.customer === customerId)
);

export default storeOrdersSlice.reducer;
